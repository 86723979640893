<template>
  <div>
    <div class="title">
      <div class="title_jl">在线简历</div>
      <div class="title_yl pointer" @click="previewClick">预览</div>
    </div>
    <div class="active" v-if="Person">
      <div class="activeText">
        <div v-if="Person.detail" class="activeTextr">
          <div class="activeName">
            <div class="nameleft">{{ Person.name }}</div>
            <img class="nameimg" :src="Person.avatar_url" v-if="Person.avatar_url" />
            <img class="nameimg" src="../../../assets/login/922028.png" v-else />
          </div>
          <div class="activeExperience">
            <div class="active_jingyan">
              <i class="el-icon-suitcase experience_icon"></i>
              <div class="experience">{{ Person.other_info.work_year }}年经验</div>
            </div>
            <div class="active_xt"></div>
            <div class="active_jingyan">
              <i class="el-icon-suitcase experience_icon"></i>
              <div class="experience" v-if="Person.school.length > 0">
                {{ Person.school[0].education }}
              </div>
              <div class="experience" v-else>无</div>
            </div>
            <div class="active_xt" v-if="Person.other_info.job_status"></div>
            <div class="active_jingyan" v-if="Person.other_info.job_status">
              <i class="el-icon-suitcase experience_icon"></i>
              <div class="experience">{{ Person.other_info.job_status }}</div>
            </div>
          </div>
          <div class="activeExperience" style="margin-top: 10px">
            <div class="active_jingyan">
              <i class="el-icon-suitcase experience_icon"></i>
              <div class="experience">{{ Person.other_info.age }}岁</div>
            </div>
            <div class="active_xt"></div>
            <div class="active_jingyan">
              <i class="el-icon-suitcase experience_icon"></i>
              <div class="experience">{{ Person.phone }}</div>
            </div>
          </div>
        </div>
        <div v-else class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">基本信息</div>
            <div class="top_right pointer" @click="navTo('/my-resume/basic')">
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div class="addition_up">展示您的基本信息，获得更多关注</div>
        </div>
        <div class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">个人优势</div>
            <div
              class="top_right pointer"
              @click="navTo('/my-resume/advantage', 1)"
              v-if="!Person.detail || !Person.detail.advantage"
            >
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div class="addition_up" v-if="Person.detail">
            {{
              Person.detail.advantage
                ? Person.detail.advantage
                : "展示您的个人优势，获得更多关注"
            }}
          </div>
        </div>
        <!-- 求职意向 -->
        <div class="addition activeTextr">
          <div class="addition_top" v-if="Person.job_intention.length == 0">
            <div class="top_left">求职意向</div>
            <div class="top_right pointer" @click="navTo('/my-resume/objective', 1)">
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div class="addition_up" v-if="Person.job_intention.length == 0">
            当前暂时无求职意向，添加后将给您推荐匹配度更高的职位
          </div>
          <div class="addition_top" v-if="Person.job_intention.length > 0">
            <div class="top_left">求职意向</div>
          </div>
         <div class="addition_ups" v-for="item in Person.job_intention" :key="item.id">
            {{ item.one_position }} ｜ {{ item.salary_min }}-{{
              item.salary_max
            }}
            ｜ {{ item.position_name }} ｜ {{ item.province }}
          </div>
        </div>
        <!-- 工作经历 -->
        <div class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">工作经历</div>
            <div
              class="top_right pointer"
              @click="navTo('/my-resume/experience', 1)"
              v-if="Person.work.length == 0"
            >
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div class="addition_up" v-if="Person.work.length == 0">
            添加您的过往工作经历，展示您的工作能力与成就
          </div>
          <div class="addition_ups-one" v-else>
            <div v-for="item in Person.work" :key="item.id" class="addition_ups-one-item">
              <div class="row-center font-weight-bolder text-main addition_ups-one-heard">
                <div>{{ item.company_name }}</div>
                <div>{{ item.job_times }}</div>
              </div>
              <div class="font-weight-bolder text-main addition_ups-one-title">
                {{ item.work_name }}
              </div>
              <div class="row text-main font-normal">
                <div>内容：</div>
                <div class="workContent">
                  <div v-for="(workContent, index) in item.splitAdd" :key="index">
                    {{ workContent }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 教育经历 -->
        <div v-if="schollShow" class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">教育经历</div>
            <div class="top_right pointer" @click="schollShow = false">
              <div class="">
                <img
                  src="../../../assets/glxt/10.png"
                  alt=""
                  v-if="Person.school.length == 0"
                />
                <img src="../../../assets/glxt/9.png" alt="" v-else />
              </div>
              <div class="right_tianjia">
                {{ Person.school.length == 0 ? "添加" : "修改" }}
              </div>
            </div>
          </div>
          <div class="addition_up" v-if="Person.school.length == 0">
            添加您的教育经历，企业更加了解您
          </div>
          <div class="addition_up-one" v-else>
            <div class="row-center font-normal text-main">
              {{ query.school[0].school_name }} ｜ {{ query.school[0].school_times }}
            </div>
            <div class="font-normal text-main addition_up-one-title">
              {{ query.school[0].major }} ｜ {{ query.school[0].education }}
            </div>
            <div class="row addition_up-one-bottom font-normal text-main">
              <div>在校经历：</div>
              <div class="workContent-one">
                <div v-for="(workContent, index) in experience_one" :key="index">
                  {{ workContent }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 编辑教育经历 -->
        <div v-else class="redact">
          <div class="active_bianji">
            <div class="bianji_gzjl">编辑教育经历</div>
            <div v-for="(item, index) in query.school" :key="index">
              <div class="bianji_flex">
                <div class="flex_left">
                  <div class="left_title">学校名称</div>
                  <el-input
                    size="small"
                    class="left_qymc"
                    v-model="item.school_name"
                    placeholder="请输入学校名称"
                  ></el-input>
                </div>
                <div class="flex_right">
                  <div class="right_title">所学专业</div>
                  <el-input
                    size="small"
                    class="right_zwmc"
                    v-model="item.major"
                    placeholder="请输入专业名称"
                  ></el-input>
                </div>
              </div>
              <div class="bianji_flex">
                <div class="flex_right" style="margin-left: 0">
                  <div class="right_title">学历</div>
                  <el-select
                    v-model="item.education_type"
                    placeholder="请选择你的学历"
                    class="right_zwmc"
                    @change="educationClick(index)"
                  >
                    <el-option
                      v-for="itemONE in education_tyoe"
                      :key="itemONE.value"
                      :label="itemONE.label"
                      :value="itemONE.value"
                      size="small"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="flex_left flex_margin">
                  <div class="left_title">就读时间</div>
                  <div style="display: flex; align-items: center">
                    <el-date-picker
                      style="margin-top: 12px"
                      class="el-xzyq_fw"
                      v-model="item.school_times_start"
                      :picker-options="pickerOptions"
                      type="month"
                      value-format="yyyy-MM"
                      size="small"
                      placeholder="选择日期"
                    >
                    </el-date-picker>
                    <div class="right_xinzi">至</div>
                    <el-date-picker
                      style="margin-top: 12px"
                      class="el-xzyq_fw"
                      v-model="item.school_times_end"
                      :picker-options="pickerOptions"
                      value-format="yyyy-MM"
                      type="month"
                      size="small"
                      placeholder="选择日期"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
              <div class="redact_xinxi">
                <div class="xinxi_left">
                  <div class="xinxi_xingming">是否全日制</div>
                  <div class="xinxi_flex" style="display: flex; align-items: center">
                    <div
                      class="xingming_nan pointer"
                      :class="item.is_full_time == 1 ? 'gender-index' : ''"
                      @click="genderClick(1, index)"
                    >
                      全日制
                    </div>
                    <div
                      class="xingming_nan pointer"
                      :class="item.is_full_time == 0 ? 'gender-index' : ''"
                      @click="genderClick(0, index)"
                    >
                      非全日制
                    </div>
                  </div>
                </div>
              </div>
              <el-input
                style="margin-top: 46px"
                type="textarea"
                :rows="6"
                placeholder="请输入在校经历：如担任的学生会职务，组织的活动等"
                v-model="item.experience"
              >
              </el-input>
              <div class="bianji_flex" style="justify-content: flex-end">
                <div class="flex_right" style="display: flex; align-items: center">
                  <div class="right_qx pointer" @click="schollShow = true">取消</div>
                  <div class="right_wc pointer" @click="submitClick">完成</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 资格证书 -->
        <div class="addition activeTextr">
          <div class="addition_top">
            <div class="top_left">资格证书</div>
            <div
              class="top_right pointer"
              @click="navTo('/my-resume/certificate', 1)"
              v-if="!Person.certificate"
            >
              <div class="">
                <img src="../../../assets/glxt/10.png" alt="" />
              </div>
              <div class="right_tianjia">添加</div>
            </div>
          </div>
          <div v-if="Person.certificate">
            <div
              class="addition_up"
              v-for="(item, index) in Person.certificate.certificates"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <div class="addition_up" v-if="Person.certificate && Person.certificate.certificates.length==0">暂未添加</div>
        </div>
      </div>
    </div>
    <mask-preview ref="maskPreview"></mask-preview>
  </div>
</template>

<script>
import api from "../../../api/user";
import toolApi from "../../../api/tool";
import maskPreview from "../components/preview.vue";
export default {
  components: { maskPreview },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      schollShow: true,
      school_times_start: "",
      school_times_end: "",
      Person: null,
      education_tyoe: [], //学历数组
      experience_one: [],
      query: {
        school: [
          {
            id: 0,
            school_name: "", //学校名称
            school_times_end: "", //在校时间
            school_times_start: "",
            major: "", //专业
            experience: "", //在校经历
            education: "", //学历，
            education_type: "",
            is_full_time: 1,
          },
        ],
      },
    };
  },

  created() {
    if (this.$route.query.id) {
      this.schollShow = false;
    }
    this.getResume();
    this.toolClick();
  },

  mounted() {},

  methods: {
    genderClick(i, index) {
      this.query.school[index].is_full_time = i;
    },
    previewClick() {
      if (!this.Person.detail || this.Person.detail.completeness < 90) {
        this.$util.msg("你的简历完善度小于90%，请继续完善简历", "warning");
        return;
      }
      this.$refs.maskPreview.show();
    },
    navTo(path, id) {
      this.$router.push({ path, query: { id } });
    },
    // 获取简历信息
    getResume() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      api.getResume().then((res) => {
        if (res.code == 200) {
          this.Person = res.data;
          if (res.data.work.length > 0) {
            res.data.work.forEach((item) => {
              if (item.to_hide == 1) {
                item.to_hide = true;
              } else {
                item.to_hide = false;
              }
              if (item.work_content) {
                item.splitAdd = item.work_content.split("\n");
              }
            });
          }
          if (res.data.school.length > 0) {
            if (res.data.school[0].experience) {
              this.experience_one = res.data.school[0].experience.split("\n");
            }
            this.query.school[0] = res.data.school[0];
          }
        }
        loading.close();
      });
    },
    // 获取配置
    toolClick() {
      toolApi.getUserConfig().then((res) => {
        if (res.code == 200) {
          let data = res.data.education_tyoe.options;
          data.shift();
          this.education_tyoe = data;
        }
      });
    },
    educationClick(index) {
      let list = this.education_tyoe.filter((item) => {
        return item.value == this.query.school[index].education_type;
      });
      this.query.school[index].education = list[0].label;
      // console.log(this.query);
    },
    // 获取用户信息
    getUserInfo() {
      api.userDetail().then((res) => {
        if (res.code == 200) {
          this.$store.commit("SET_USER", res.data);
        }
      });
    },
    // 验证规则
    yzgz() {
      let school = this.query.school[0];
      if (school.school_name == "") {
        this.$util.msg("请输入学校名称", "error");
        return false;
      } else if (school.major == "") {
        this.$util.msg("请输入专业名称", "error");
        return false;
      } else if (school.education == "") {
        this.$util.msg("请选择学历", "error");
        return false;
      } else if (school.school_times_start == "" || school.school_times_end == "") {
        this.$util.msg("请选择就读时间", "error");
        return false;
      }
      return true;
    },
    // 完成
    submitClick() {
      if (!this.yzgz()) return;
      this.query.school[0].school_times =
        this.school_times_start + "-" + this.school_times_end;
      api.setResume(this.query).then((res) => {
        if (res.code == 200) {
          this.$util.msg(res.msg);
          this.getResume();
          this.getUserInfo();
          this.schollShow = true;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 51px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #f3f3f3;
  border-bottom: 2px solid #f3f3f3;
  .title_jl {
    font-size: 14px;
    font-weight: 400;
    color: #414a60;
    margin-left: 30px;
  }
  .title_yl {
    font-size: 14px;
    font-weight: 400;
    color: #126bf9;
    margin-right: 30px;
  }
}
.active {
  background: #ffffff;
  overflow: hidden;
  .activeText {
    margin: 17px 0px 30px;
    .activeName {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nameleft {
        font-size: 24px;
        font-weight: 400;
        color: #24272e;
      }
      .nameimg {
        border-radius: 50%;
        width: 56px;
        height: 56px;
        overflow: hidden;
      }
    }
    .activeExperience {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .active_xt {
        width: 2px;
        height: 12px;
        background: #e0e0e0;
        margin: 0 14px 0 21px;
      }
      .active_jingyan {
        display: flex;
        align-items: center;
        .experience_icon {
          width: 14px;
          height: 14px;
        }
        .experience {
          margin-left: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #414a60;
        }
      }
    }
    .addition {
      margin: 50px 0 0 0;
      .addition_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top_left {
          font-size: 18px;
          font-weight: 400;
          color: #414a60;
        }
        .top_right {
          display: flex;
          align-items: center;
          .right_blue {
            width: 13px;
            height: 13px;
            background-color: #126bf9;
          }
          .right_tianjia {
            font-size: 13px;
            font-weight: 400;
            color: #126bf9;
            margin-left: 10px;
          }
        }
      }
      .addition_up {
        margin-top: 30px;
        font-size: 14px;
        font-weight: 400;
        color: #aeb1bb;
      }
      .addition_up-one {
        margin-top: 28px;
        .addition_up-one-title {
          margin: 20px 0;
        }
        .addition_up-one-bottom {
          > div:first-child {
            font-size: 15px;
          }
          .workContent-one {
            line-height: 23px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.activeTextr {
  padding: 0 30px;
}
.active_bianji {
  padding: 28px 30px 25px;
}
.redact {
  margin-top: 50px;
  width: 100%;
  background: #f8f9fb;
}
// 每个页面编辑不同的CSS
.bianji_gzjl {
  font-size: 16px;
  font-weight: 400;
  color: #414a60;
}
.bianji_flex {
  display: flex;
  align-items: center;
  .flex_left {
    margin-top: 24px;
    .left_title {
      font-size: 14px;
      font-weight: 400;
      color: #414a60;
    }
    .left_qymc {
      margin-top: 10px;
      width: 316px;
    }
  }
  .flex_right {
    margin-top: 24px;
    margin-left: 181px;
    .right_title {
      font-size: 14px;
      font-weight: 400;
      color: #414a60;
    }
    .right_zwmc {
      margin-top: 12px;
      width: 316px;
    }
    .right_qx {
      width: 92px;
      height: 36px;
      background: #ffffff;
      border: 1px solid #e3e7ed;
      font-size: 14px;
      font-weight: 400;
      color: #414a60;
      text-align: center;
      line-height: 36px;
    }
    .right_wc {
      width: 90px;
      height: 36px;
      background: #126bf9;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 36px;
      margin: 0 20px;
    }
    .right_xyd {
      width: 90px;
      height: 36px;
      background: #126bf9;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 36px;
    }
  }
  .right_xinzi {
    font-size: 14px;
    font-weight: 400;
    color: #414a60;
    margin: 10px 10px 0;
  }
  .el-xzyq_fw {
    width: 141px;
  }
}
.redact_xinxi {
  margin-top: 1.5rem;
  .xinxi_flex {
    margin-top: 0.75rem;
    .xingming_nan {
      margin-right: 20px;
      width: 9.1875rem;
      height: 2.375rem;
      background: #ffffff;
      border: 0.0625rem solid #e3e7ed;
      font-size: 0.875rem;
      font-weight: 400;
      color: #9fa3b0;
      line-height: 2.375rem;
      text-align: center;
    }
    .gender-index {
      background: #126bf9;
      color: #ffffff;
    }
  }
}
.flex_margin {
  margin-left: 181px;
}
.addition_ups {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #414a60;
}
.addition_ups-one {
  margin-top: 28px;
  .addition_ups-one-item {
    margin-bottom: 20px;
  }
  .addition_ups-one-item:last-child {
    margin-bottom: 0;
  }
  .addition_ups-one-heard {
    font-size: 15px;
    div:last-child {
      font-weight: 400;
      color: #9fa3b0;
      font-size: 13px;
      margin-left: 20px;
    }
  }
  .addition_ups-one-title {
    font-size: 15px;
    margin: 20px 0px;
  }
  .workContent {
    line-height: 23px;
  }
}
</style>
